body, html, #root {
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #f4f4f8;
}

body * {
  box-sizing: border-box;
}

.app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.wrapper {
  display: grid;
  height: 100%;
  width:100%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  background: #091619;
}

h1 {
  text-transform: uppercase;
  margin: 0;
  font-size: 3vw;
  letter-spacing: -2px;
}

.frame {
  border: none;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}

iframe {
  height: 100%;
  width: 100%;
  border: none;
}

main {
  padding: 5vh 5vw;
  overflow: auto;
}

.formitem {
  margin: 2em 0;
}

.formitem > label {
  font-weight: bold;
  font-size: 1.125em;
  text-transform: uppercase;
}

input[type="text"], textarea, select {
  display: block;
  margin: 0.5em 0;
  width: 100%;
  max-width: 60ch;
  font-size: 1em;
  padding: 0.5em;
  background: rgba(0, 0, 0, 0.5);
  color: inherit;
  font-family: inherit;
  border: 1px solid #20bf55;
}

textarea {
  height: 10em;
  resize: vertical;
}

.checkboxes {
  margin: 0.5em 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5em;
}

.checkboxes div {
  display: flex;
  width: calc(25% - 0.5rem);
  align-items: center;
  gap: 0.5em;
}

.checkboxes label {
  font-size: 0.95em;
  white-space: nowrap;
  word-break: keep-all;
}

.download {
  background: #1663fe;
  color: #f4f4f8;
  border: none;
  display: inline-block;
  padding: 1em 1em 1.2em;
  border-radius: 4px;
  line-height: 1;
  margin-top: 1em;
  font-size: 0.925em;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
}

@media only screen and (max-width: 50em)  {
  .app {
    overflow: auto;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
  }
  .frame {
    margin-top: 2em;
    height: 100vh;
    min-height: 100vh;
  }
}